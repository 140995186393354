import React, { useState, useEffect } from 'react';
import './App.css'; // Import pico.css or directly link it in index.html
import '@picocss/pico'

const presetColors = ['#FFBF00', '#FFA23A', '#C52F21', '#A780D4', '#F99EAE', '#0AB1B1', '#007A50', '#4040BF', '#7385A9', '#BEB8A7', '#FFFFFF', '#000000'];

function App() {
  const [bgColor, setBgColor] = useState('#FFFFFF');
  const [customColor, setCustomColor] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);

  // Load saved color from localStorage
  useEffect(() => {
    const savedColor = localStorage.getItem('bgColor');
    if (savedColor) {
      setBgColor(savedColor);
    }

    // Show welcome dialog only once per day
    const lastVisit = localStorage.getItem('lastVisit');
    const today = new Date().toDateString();

    if (lastVisit !== today) {
      setShowWelcomeDialog(true);
      localStorage.setItem('lastVisit', today);
    }
  }, []);

  const handleColorChange = (color) => {
    setBgColor(color);
    localStorage.setItem('bgColor', color);
    //setShowColorPicker(false);
  };

  const handleCustomColorInput = (e) => {
    setCustomColor(e.target.value);
  };

  const handleCustomColorSubmit = () => {
    handleColorChange(customColor);
  };

  const handleBackgroundClick = () => {
    if (showColorPicker) {
      setShowColorPicker(false);
    }
    else {
      setShowColorPicker(true);
    }
  };
  const closeWelcomeDialog = () => {
    setShowWelcomeDialog(false);
  };

  const handleDialogClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'dialog') {
      setShowColorPicker(false);
      setShowWelcomeDialog(false);
    }
  };

  return (
    <>
      <div
        id="background"
        style={{ backgroundColor: bgColor, height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0, zIndex: 0 }}
        onClick={handleBackgroundClick}
      />
      {
        <dialog open={showColorPicker} style={{ zIndex: 1 }} onClick={handleDialogClick}>
          <article onClick={(e) => e.stopPropagation()}>
            <header>
              <button aria-label="Close" rel="prev" onClick={() => setShowColorPicker(false)}></button>
              <p><strong>🎨 Select Background Color</strong></p>
            </header>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
              {presetColors.map((color) => (
                <div
                  key={color}
                  onClick={() => handleColorChange(color)}
                  style={{
                    backgroundColor: color,
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                    borderRadius: '50%',
                  }}
                ></div>
              ))}
            </div>
            <div style={{ marginBottom: '10px' }}>or pick your own</div>
            <div style={{ marginBottom: '10px' }}>
              <input
                type="color"
                value={customColor}
                onChange={(e) => setCustomColor(e.target.value)}
                onBlur={handleCustomColorSubmit}
                aria-label="Color picker"
              />
            </div>
          </article>
        </dialog>
      }

      {
        <dialog open={showWelcomeDialog} style={{ zIndex: 1 }} onClick={handleDialogClick}>
          <article onClick={(e) => e.stopPropagation()}>
            <header>
              <button aria-label="Close" rel="prev" onClick={closeWelcomeDialog}></button>
              <p><strong>🖥️ Welcome!</strong></p>
            </header>
            <p>Use Clean Page for when you need a clean backdrop for screenshots, screen recording or presentations.</p>
            <p>Click anywhere on background to change color.</p>
            <p>Press F11 on your keyboard to toggle fullscreen mode.</p>
            {/*<div style={{ margin: '20px 0' }}>[Your Ad Here]</div>*/}
            <footer>
              <button onClick={closeWelcomeDialog}>Got it!</button>
            </footer>
          </article>
        </dialog>
      }
    </>
  );
}

export default App;